import { useKeycloak } from '@react-keycloak/web';
import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRoutes } from '../../routes/useRoutes';
import { AuthContext, AuthContextProps } from './AuthContext';
import { ParsedToken } from './AuthModel';
import { parseToken } from './utils';

const mapAuthState = (token?: string) => {
  const parsedToken = parseToken(token);

  return {
    token,
    parsedToken,
    clientAdmin: parsedToken?.clientadmin,
  };
};

interface AuthState {
  token?: string;
  parsedToken?: ParsedToken;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { keycloak } = useKeycloak();
  const { isPrivateRoute } = useRoutes();
  const [auth, setAuth] = useState<AuthState>(mapAuthState(keycloak.token));

  const onAuthRefreshSuccess = async () => {
    if (keycloak.token) {
      const mappedState = mapAuthState(keycloak.token);
      setAuth(mappedState);

      Sentry.setUser({
        username: mappedState?.parsedToken?.email,
      });
      sessionStorage.setItem('kc_token', keycloak.token);
    }
    if (keycloak.refreshToken) {
      sessionStorage.setItem('kc_refreshToken', keycloak.refreshToken);
    }
  };

  const onAuthSuccess = () => {
    if (keycloak.isTokenExpired(30)) {
      onTokenExpired();
      return;
    }

    /* Fixes issue when redirect is performed from keycloak (signin)
     * Will prevent that the 404-page is served even if correct route
     * exist and is matching.
     */
    const oldHash = window.location.hash;
    window.location.hash = `${oldHash}/`;
    window.location.hash = oldHash;
    onAuthRefreshSuccess();
  };

  const onTokenExpired = async () => {
    try {
      await keycloak.updateToken(30);
    } catch (e) {
      if (isPrivateRoute) {
        throw new Error('Unable to renew token');
      }
    }
  };

  useEffect(() => {
    keycloak.onAuthRefreshSuccess = onAuthRefreshSuccess;
    keycloak.onAuthSuccess = onAuthSuccess;
    keycloak.onTokenExpired = onTokenExpired;
  }, []);

  const signOut = useCallback(() => {
    Sentry.configureScope((scope) => scope.setUser(null));
    keycloak.logout();
    sessionStorage.removeItem('kc_token');
    sessionStorage.removeItem('kc_refreshToken');
  }, [keycloak.logout]);

  const value: AuthContextProps = useMemo(
    () => ({
      ...auth,
      authenticated: !!keycloak.authenticated,
      signOut,
    }),
    [auth, keycloak.authenticated, signOut]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
