export const translations = {
  sv: {
    translation: {
      title_base: 'Kundportal - Remondis',

      // Footer
      footer_copyright: '© Remondis Sweden AB',
      footer_link_terms_of_use_text: 'Användarvillkor',
      footer_link_terms_of_use_title: 'Se våra användarvillkor',
      footer_link_privacy_policy_text: 'Integritetspolicy',
      footer_link_privacy_policy_title: 'Se vår integritetspolicy',

      remondis_contact_email: 'kundservice@remondis.se',

      // Main Navigation
      navigation_home_title: 'Hem',

      navigation_order_title: 'Beställ',
      navigation_services_title: 'Beställ tömning',
      navigation_services_description:
        'Alla era aktiva tjänster med eventuella planerade tömningar. Önskar ni boka en tömning? Klicka på tjänsten och boka direkt.',
      navigation_scheduled_title: 'Planerade tömningar',
      navigation_scheduled_description:
        'Få en överblick av planerade tömningar på era arbetsplatser.',

      navigation_trends_title: 'Trender',
      navigation_trends_description:
        'Följ löpande era materialvolymer och jämför enkelt olika materialslag och olika tidsperioder.',
      navigation_waste_hierarchy_description:
        'Analysera er avfallssortering enligt avfallstrappans fem nivåer och identifiera eventuella avvikelser med hjälp av graferna.',
      navigation_workplace_analysis_description:
        'Se er avfallssortering efter Avfallstrappans fem nivåer uppdelat per arbetsplats. Hitta eventuella avvikelser och identifiera förbättringspotential.',
      navigation_report_co2_title: 'CO₂-påverkan',
      navigation_report_co2_description:
        'Analysera er klimatpåverkan utifrån ett livscykelperspektiv. Negativa CO₂-utsläpp indikerar klimatnytta medan positiva indikerar på en klimatpåverkan. Jämför olika tidsintervaller och materialslag.',
      navigation_reports_title: 'Statistik',
      navigation_report_statistics_title: 'Statistikrapport',
      navigation_report_statistics_description:
        'Se alla transaktioner (hämtningar, tömningar, utställningar, månadshyror etc.) som har genomförts på era arbetsplatser.',
      navigation_report_waste_title: 'Avfallsregister för farligt avfall',
      navigation_report_waste_description:
        'Som ombud rapporterar Remondis farligt avfall åt er som avfallsproducent. Här får ni kvittens över vad vi rapporterat till Naturvårdsverkets avfallsregister.',
      navigation_estates_title: 'Fastigheter',
      navigation_estates_description:
        'Här kan ni se statistik för allt avfall som producerats av era hyrestagare och er själva, aggregerat per fastighet.',
      navigation_estates_waste_hierarchy_description:
        'Analysera er avfallshantering enligt avfallstrappan, uppdelad per fastighet. Sträva efter att maximera andelen avfall som hanteras i de övre nivåerna av trappan, där de mest hållbara alternativen prioriteras.',
      navigation_administration_title: 'Administration',
      navigation_users_title: 'Användare',
      navigation_users_description: 'Hantera användare och administratörer inom er organisation.',
      navigation_api_clients_description: 'Se och hantera API-konton för portalen.',
      navigation_workplaces_title: 'Arbetsplatser',
      navigation_workplaces_description:
        'Se de arbetsplatser som du har tillgång till och få en översikt över dess aktiva tjänster.',
      navigation_sustainability_report_title: 'Miljörapport',
      navigation_sustainability_report_description:
        'Detaljerad analys av er avfallshantering baserat på olika avfallstyper.',
      navigation_scope3_description:
        'Analysera er CO2e -påverkan som uppstår genom avfallshantering. Klimatpåverkan från avfallshantering, förberedelse inför återvinning och transporter kopplade till avfallshanteringen.',
      navigation_estates_scope3_description:
        'Analysera CO2e-påverkan från avfallshantering i era fastigheter. Detta ger en överblick av klimatpåverkan från allt avfall som genererats både av era hyresgäster och er själva, sammanställt per fastighet.',

      navigation_footer_title: 'REMONDIS Sweden AB',
      navigation_footer_text: 'Få full kontroll över er återvinningsresa',

      // Cookie Banner
      cookie_consent_heading: 'Hantera cookieinställningar',
      cookie_consent_description_start:
        'REMONDIS och våra utvalda partners använder cookies för att du ska få bästa möjliga upplevelse av den här sidan. Besök vår <Link>Integritetspolicy</Link> för att läsa mer om de cookies vi använder. Du kan när som helst återkalla eller ändra ditt samtycke, genom att klicka på “Hantera cookieinställningar” på botten av sidan.',
      cookie_consent_description_end: '.',
      cookie_consent_terms_link_text: 'Remondis Sweden ABs villkor',
      cookie_consent_terms_link_title: 'Bekräfta mina val',
      cookie_consent_accept_button_text: 'Bekräfta mina val',
      cookie_consent_accept_button_title: 'Acceptera cookies',

      // Maintenance Banner
      maintenance_banner_message:
        'De tekniska störningarna är åtgärdade. Tack för ditt tålamod! För eventuella frågor, ring vår kundtjänst på 0771 – 545 000.',
      // Impersonate Bar
      impersonate_bar_active: 'Speglad vy aktiv',
      impersonate_bar_terminate_button_text: 'Avsluta spegling',
      impersonate_bar_terminate_button_title: 'Avsluta spegning av användaren {{user}}',

      // Route: Home
      home_title: 'Hem',
      home_h1: 'Hej {{user}}, välkommen till vår kundportal!',
      home_text:
        'I vår portal, kan ni dra nytta av många praktiska funktioner för att förbättra er organisations prestation. På våra statistiksidor kan ni enkelt hålla koll på alla transaktioner som har genomförts på era arbetsplatser. Genom trender kan ni följa er organisations återvinning över tid och med hjälp av jämförelsevärden kan ni upptäcka avvikelser och förbättringsområden. Utforska våra verktyg och få insikter för att ta er organisation till nästa nivå!',
      home_contact:
        'Har ni några frågor eller behöver hjälp? <Link>Kontakta oss gärna så hjälper vi dig</Link>',
      home_contact_link_title: 'Skicka e-post till kundtjänst för portalen',
      home_workplace_analysis_title:
        'Ni har återvunnit {{recycled}} % ({{totalWaste}} kg) av ert avfall föregående månad',
      home_workplace_analysis_recycled: 'Återvunnet',
      home_workplace_analysis_total_recycled: 'Totalt återvunnet',
      home_workplace_analysis_other: 'Övrig hantering',
      home_workplace_analysis_description:
        'Grafen visar fördelning återvunnet och övrig hantering för föregående månad.',
      home_workplace_analysis_all_workplaces: 'Alla arbetsplatser',

      // Route: Waste Hierarchy
      waste_hierarchy_title: 'Avfallstrappan',
      waste_h1: 'Avfallstrappan',
      waste_detail_text: 'avfallstyper',
      waste_detail_open_modal: 'Läs mer',
      waste_detail_modal_title: 'Återvunnet',
      waste_description: `Analysera er avfallssortering och hitta eventuella avvikelser med hjälp av graferna nedan. Graferna och data går att ladda ner för att användas i andra presentationer och rapporter.

      Avfallstrappan, eller ”avfallshierarkin”, är ett EU-direktiv som är antaget i den svenska miljöbalken och styr hur vårt avfall ska tas om hand. Så här disponeras er avfall enligt avfallstrappans fem nivåer: minimera/förebygga, återanvända, återvinna, utvinna energi och deponera. Sträva alltid efter att ha så höga staplar som möjligt i de översta nivåerna.`,
      waste_graph_text_cta:
        'Sträva alltid efter att ha så höga staplar som möjligt i de översta nivåerna.',
      waste_graph_text_cta_link_text: 'Läs mer.',
      waste_graph_text_cta_url: 'https://www.naturskyddsforeningen.se/faktablad/avfallstrappan/',
      waste_link_url: 'https://www.naturskyddsforeningen.se/faktablad/avfallstrappan/',
      waste_link_text: 'Läs mer om Avfallstrappan',
      waste_recycle_increase:
        'Det är en ökning med {{comparison}} procentenheter från {{month}} då ni återvann {{monthPercentage}} % ({{monthWeight}} kg)',
      waste_recycle_decrease:
        'Tyvärr visar vår data att det varit en minskning med {{comparison}} procentenheter från {{month}} då ni återvann {{monthPercentage}} % ({{monthWeight}} kg)',

      // Route: /reports/statistics & /reports
      reports_title: 'Statistikrapport',
      reports_h1: 'Statistikrapport',
      reports_preamble:
        'Här kan du se alla transaktioner som har genomförts på de arbetsplatser du har behörighet att se. Transaktioner innebär hämtningar, tömningar, utställningar, månadshyror etc. Här kan du även ta ut en rapport i form av en CSV eller Excel-fil.',
      reports_fetch_data_error: 'Kunde inte hämta data. Försök igen.',
      reports_add_filter_btn_text: 'Lägg till filter',
      reports_add_filter_btn_title: 'Lägg till filter',
      reports_save_filter_btn_text: 'Spara mall',
      reports_save_filter_btn_title: 'Spara aktiva filter som en mall',
      reports_filter_panel_tab_add_filter_text: 'Lägg till nytt filter',
      reports_filter_panel_tab_add_filter_title: 'Lägg till nytt filter',
      reports_filter_panel_tab_saved_filter_text: 'Mallar',
      reports_filter_panel_tab_saved_filter_title: 'Visa mallar',
      reports_save_filter_panel_title: 'Spara mall',
      reports_save_filter_panel_save_btn_text: 'Spara',
      reports_save_filter_panel_save_btn_title: 'Spara mall',
      reports_save_filter_panel_description:
        'Spara din nuvarande filtrering som en mall. Den går sedan att komma åt via {{savedFilterBtn}}.',
      reports_save_filter_panel_input_name_label: 'Namn på mallen',
      reports_save_filter_panel_date_label: 'Datum',
      reports_save_filter_panel_pinned_label: 'Pinnad kolumn',
      reports_save_filter_panel_hidden_columns_label: 'Dolda kolumner',
      reports_save_filter_panel_input_name_error:
        'För att kunna spara mallen krävs det att du anger ett namn.',
      reports_saved_filter_panel_no_results:
        'Du har inga sparade mallar. För att spara en mall - gör en filtrering och klicka sedan på {{saveFilterBtn}}.',
      reports_saved_filter_panel_remove_confirm:
        'Är du säker på att du vill ta bort filtret "{{filterName}}"?',
      reports_saved_filter_panel_select_btn_text: 'Applicera mall',
      reports_saved_filter_panel_select_btn_title: 'Applicera mall "{{filterName}}"',
      reports_saved_filter_panel_remove_btn_text: 'Ta bort mall',
      reports_saved_filter_panel_remove_btn_title: 'Ta bort mallen "{{filterName}}"',
      reports_saved_filter_panel_rename_btn_text: 'Ändra namn',
      reports_saved_filter_panel_rename_btn_title: 'Ändra namn på mallen "{{filterName}}"',
      reports_saved_filter_panel_box_menu_btn_title: 'Visa fler alternativ',
      reports_date_filter_btn_title: 'Ändra datum',
      reports_filter_btn_edit_title: 'Ändra filter',
      reports_filter_btn_clear_title: 'Rensa filter',
      reports_filter_panel_title: 'Filter',
      reports_filter_panel_section_date: 'Datum',
      reports_filter_panel_date_select_label: 'Välj datum',
      reports_filter_panel_date_select_option_date_last_month: 'Denna månaden',
      reports_filter_panel_date_select_option_date_previous_month: 'Föregående månad',
      reports_filter_panel_date_select_option_date_last_twelve_months: 'Senaste tolv månaderna',
      reports_filter_panel_date_select_option_date_this_year: 'Detta år',
      reports_filter_panel_date_select_option_date_custom: 'Välj period',
      reports_filter_panel_clear_btn_text: 'Rensa alla filter',
      reports_filter_panel_clear_btn_title: 'Rensa alla aktiva filter',
      reports_filter_panel_apply_btn_text: 'Spara',
      reports_filter_panel_apply_btn_title: 'Spara',
      reports_filter_panel_abort_btn_title: 'Avbryt',
      reports_filter_panel_search_placeholder: 'Sök...',
      reports_saved_filter_panel_update_success: 'Mall sparad.',
      reports_saved_filter_panel_update_error: 'Något gick fel, försök igen',
      reports_settings_btn_open_title: 'Öppna tabellinställningar',
      reports_settings_panel_title: 'Inställningar',
      reports_settings_panel_description:
        'Välj vilka kolumner som ska synas i tabellen. Det går även att välja en kolumn som ska pinnas fast till vänster vid scroll.',
      reports_settings_panel_pin_btn_text: 'Pinna fast',
      reports_settings_panel_pin_btn_title: 'Pinna fast kolumn "{{columnName}}"',
      reports_settings_panel_unpin_btn_text: 'Pinnad',
      reports_settings_panel_unpin_btn_title: 'Ta bort "{{columnName}}" som pinnad kolumn',
      reports_settings_panel_toggle_checkbox_on_title: 'Dölj kolumnen "{{columnName}}"',
      reports_settings_panel_toggle_checkbox_off_title: 'Visa kolumnen "{{columnName}}"',
      reports_date_from_label: 'Från',
      reports_date_to_label: 'Till',
      reports_details_panel_title: 'Detaljer',
      reports_table_sort_btn_title: 'Sortera efter',
      reports_table_utility_menu_open_btn_title: 'Visa fler alternativ',
      reports_table_utility_menu_item_details_btn_text: 'Visa detaljer',
      reports_table_utility_menu_item_details_btn_title: 'Visa detaljer',
      reports_table_no_results_alert: 'Ingen data för den valda perioden.',
      reports_download_btn_text: 'Ladda ned',
      reports_download_btn_title: 'Ladda ned data',
      reports_download_btn_text_order: 'Beställ',
      reports_download_btn_title_order: 'Beställ data',
      reports_download_csv_btn_text: 'Ladda ned CSV',
      reports_download_png_btn_text: 'Ladda ned PNG',
      reports_download_csv_btn_title: 'Ladda ned datan som CSV',
      reports_download_csv_xlsx_text: 'Ladda ned till Excel',
      reports_download_csv_xlsx_title: 'Ladda ned data som en Excel-fil',
      reports_download_csv_error: 'Kunde inte ladda ned data. Försök igen.',
      reports_export_heading: 'Exportera statistik',
      reports_export_preamble: 'Beställ eller ladda ned data som CSV eller som en Excel-fil',
      reports_export_preamble_download: 'Ladda ned data som CSV eller som en Excel-fil',
      reports_export_preamble_order: 'Beställ data som CSV eller som en Excel-fil',
      reports_order_xlsx_title: 'Beställ Excel via mail',
      reports_order_xlsx_text: 'Beställ Excel via mail',
      reports_order_csv_text: 'Beställ CSV via mail',
      reports_order_csv_title: 'Beställ CSV via mail',

      // Route: /reports/waste
      hazardous_waste_title: 'Avfallsregister för farligt avfall',
      hazardous_waste_h1: 'Avfallsregister för farligt avfall',
      hazardous_waste_preamble: `
      Utifall att ni har anlitat REMONDIS som ombud för rapportering av farligt avfall till Naturvårdsverkets avfallsregister, i enlighet med 6 kap 11 § avfallsförordningen (2020:614), får ni i denna vy en sammanställning av vad som har rapporterats.
      <br /><br />
      Önskar ni hjälp med er rapportering av farligt avfall? Kontakta oss på <strong><Link>{{email}}</Link></strong> så återkommer vi inom kort.
      `,
      hazardous_waste_transport_document_download: 'Ladda ner',
      hazardous_waste_transport_document_error: 'Något gick fel vid hämtning av dokument',
      hazardous_waste_table_header_ewc: 'Avfallskod (EWC)',
      hazardous_waste_table_header_waste: 'Avfall',
      hazardous_waste_table_header_weight: 'Vikt (kg)',
      hazardous_waste_table_header_type: 'R&D',
      hazardous_waste_table_header_environmentalAgencyConfirmation: 'Bekräftelse NVV',
      hazardous_waste_table_header_transportDocument: 'Transportdokument',
      hazardous_waste_table_header_date: 'Datum',
      hazardous_waste_table_header_orderNumber: 'Ordernummer',
      hazardous_waste_table_header_littera: 'Littera',
      hazardous_waste_table_header_wasteId: 'Avfalls-ID',
      hazardous_waste_table_header_time: 'Rapporteringstid',
      hazardous_waste_table_header_transportType: 'Transportsätt',
      hazardous_waste_table_header_transporter: 'Transportör',
      hazardous_waste_table_header_transporterId: 'Transport orgnr',
      hazardous_waste_table_header_producer: 'Producent',
      hazardous_waste_table_header_producerId: 'Producent orgnr',
      hazardous_waste_table_header_producerAddress: 'Producent adress',
      hazardous_waste_table_header_producerCity: 'Producent ort',
      hazardous_waste_table_header_receiver: 'Mottagare',
      hazardous_waste_table_header_receiverId: 'Mottagare orgnr',
      hazardous_waste_table_header_receiverAddress: 'Mottagare adress',
      hazardous_waste_table_header_receiverCity: 'Mottagare ort',

      // Routes: /reports/co2
      co2_title: 'CO₂-påverkan',

      // Route: Estates
      estates_title: 'Fastighetsrapport',
      estates_h1: 'Fastighetsrapport',
      estates_preamble:
        'Här kan ni se statistik för allt avfall som producerats av era hyrestagare och er själva, aggregerat per fastighet.',

      estates_report_header_date: 'Datum',
      estates_report_header_description: 'Avfall',
      estates_report_header_ewcCode: 'Avfallskod (EWC)',
      estates_report_header_propertyDesignation: 'Fastighetsbeteckning',
      estates_report_header_quantity: 'Antal',
      estates_report_header_rdCode: 'R&D kod',
      estates_report_header_rdSubcode: 'R&D underkod',
      estates_report_header_unit: 'Enhet',
      estates_report_header_volume: 'Volym (kbm)',
      estates_report_header_weight: 'Vikt (kg)',

      // Route: /estates/waste-hierarchy
      estates_waste_hierarchy_h1: 'Avfallstrappan per fastighet',
      estates_waste_hierarchy_preamble:
        'Analysera er avfallshantering utifrån avfallstrappan, uppdelat per fastighet. Identifiera eventuella avvikelser och hitta förbättringspotential med hjälp av rapporten nedan. Sträva efter att maximera andelen avfall som placeras i de högre nivåerna av avfallstrappan. Rapporten går att ladda ner som Excel eller CSV.',

      estates_hierarchy_header_propertyDesignation: 'Fastighet',
      estates_hierarchy_header_reuse: 'Återanvändning',
      estates_hierarchy_header_recycle: 'Återvinning',
      estates_hierarchy_header_biological: 'Biologisk behandling',
      estates_hierarchy_header_energy: 'Energiutvinning',
      estates_hierarchy_header_landfill: 'Deponering',
      estates_hierarchy_header_other: 'Okategoriserat',

      // Route: Trends
      trends_title: 'Avfallshistorik',
      trends_h1: 'Avfallshistorik',
      trends_description:
        'Analysera er avfallshistorik och hitta eventuella avvikelser med hjälp av graferna nedan. Graferna går att ladda ner för att användas i andra presentationer.',
      trends_summary_label: 'Total vikt',
      trends_summary_disclaimer: 'För den valda perioden baserat på de filter som är aktiva',
      trends_filter_panel_clear_btn_text: 'Rensa alla filter',
      trends_download_graph_btn_text: 'Ladda ned graf',
      trends_download_graph_btn_title: 'Ladda ned graf som PNG',
      trends_export_heading: 'Exportera graf',
      trends_export_preamble: 'Ladda ner data som bild eller Excel',
      trends_filter_btn_edit_title: 'Ändra filter',
      trends_filter_btn_clear_title: 'Rensa filter',
      trends_add_filter_btn_title: 'Lägg till filter',
      trends_add_filter_btn_text: 'Lägg till filter',
      trends_date_filter_btn_title: 'Ändra datum',
      trends_compare_filter_btn_title: 'Jämförelse',
      trends_compare_filter_btn_arialabel: 'Välj jämförelse',
      trends_compare_option_total: 'Ingen jämförelse',
      trends_compare_option_monthly: 'Jämför månadsvis',
      trends_compare_option_quarter: 'Jämför kvartal',
      trends_compare_option_same_period_previous_year: 'Jämför med samma period föregående år',
      trends_compare_graph_legend_total_label: 'Totalt',
      trends_compare_graph_legend_curretn_period_label: 'Nuvarande period',
      trends_compare_graph_legend_previous_year_label: 'Föregående års period',
      trends_filter_panel_title: 'Filter',
      trends_filter_panel_section_date: 'Datum',
      trends_filter_panel_tab_add_filter_text: 'Lägg till nytt filter',
      trends_filter_panel_tab_add_filter_title: 'Lägg till nytt filter',
      trends_filter_panel_date_select_option_previous_month: 'Föregående månad',
      trends_filter_panel_date_select_option_last_twelve_months: 'Senaste tolv månaderna',
      trends_filter_panel_date_select_option_this_year: 'Detta år',
      trends_filter_panel_date_select_option_date_custom: 'Välj period',
      trends_filter_panel_apply_btn_text: 'Spara',
      trends_filter_panel_abort_btn_title: 'Avbryt',
      trends_filter_panel_apply_btn_title: 'Spara',
      trends_save_filter_btn_text: 'Spara mall',
      trends_save_filter_btn_title: 'Spara aktiva filter som en mall',
      trends_filter_panel_tab_saved_filter_text: 'Mallar',
      trends_filter_panel_tab_saved_filter_title: 'Visa mallar',
      trends_filter_panel_search_placeholder: 'Sök...',
      trends_save_filter_panel_title: 'Spara mall',
      trends_save_filter_panel_save_btn_text: 'Spara',
      trends_save_filter_panel_save_btn_title: 'Spara mall',
      trends_save_filter_panel_description:
        'Spara din nuvarande filtrering som en mall. Den går sedan att komma åt via {{savedFilterBtn}}.',
      trends_save_filter_panel_input_name_label: 'Filternamn',
      trends_save_filter_panel_input_name_error:
        'För att kunna spara mallen krävs det att du anger ett namn.',
      trends_saved_filter_panel_select_btn_text: 'Applicera mall',
      trends_saved_filter_panel_select_btn_title: 'Applicera mallen "{{filterName}}"',
      trends_saved_filter_panel_remove_btn_text: 'Ta bort mall',
      trends_saved_filter_panel_remove_confirm:
        'Är du säker på att du vill ta bort mallen "{{filterName}}"?',
      trends_saved_filter_panel_remove_btn_title: 'Ta bort mallen "{{filterName}}"',
      trends_saved_filter_panel_rename_btn_text: 'Ändra namn',
      trends_saved_filter_panel_rename_btn_title: 'Ändra namn på mallen "{{filterName}}"',
      trends_saved_filter_panel_no_results:
        'Du har inga sparade mallar. För att spara en mall - gör en filtrering och klicka sedan på {{saveFilterBtn}}.',
      trends_saved_filter_panel_update_success: 'Mall sparad.',
      trends_saved_filter_panel_update_error: 'Något gick fel, försök igen',

      // Route: Users
      users_title: 'Användare',
      users_h1: 'Användare',
      users_description:
        'Här kan ni skapa nya administratörer, användare och redigera befintliga konton. Vänligen <0>kontakta oss</0> om du har frågor eller behöver hjälp.',
      users_description_link_title: 'Kontakta oss via e-post',
      users_description_no_authority:
        'Här listas alla användare och administratörer inom din organisation.',
      users_description_user_admin:
        'Här listas och hanterar ni alla kunders administratörer och användare.',
      users_alert_no_authority: 'Du saknar behörighet för att se innehållet på denna sida.',
      users_add_customer_admin_btn_text: 'Lägg till administratör',
      users_add_customer_admin_btn_title: 'Lägg till administratör',
      users_add_user_btn_text: 'Lägg till användare',
      users_add_user_btn_title: 'Lägg till användare',
      users_search_placeholder: 'Sök efter en kund',
      users_search_help:
        'Du kan söka på ett organisationsnummer, kundnummer, e-postadress eller namn.',
      users_search_results: '{{hits}} aktiva användare för {{activeSearch}}',
      users_reset_password_success: 'Lösenordet har återställts för användare {{email}}.',
      users_reset_password_error:
        'Det gick inte återställa lösenordet för användare {{email}}. Försök igen.',
      users_enable_success: 'Användare {{email}} är aktiverad.',
      users_enable_error: 'Det gick inte att aktivera användare {{email}}. Försök igen.',
      users_disable_success: 'Användare {{email}} är inaktiverad.',
      users_disable_error: 'Det gick inte att inaktivera användare {{email}}. Försök igen.',
      users_remove_user_success: 'Användare {{email}} är borttagen.',
      users_remove_user_error: 'Det gick inte att ta bort användare {{email}}. Försök igen.',
      users_confirm_remove_user: 'Är du säker på att du vill ta bort användare "{{user}}"?',
      users_confirm_restore_password:
        'Är du säker på att du vill återställa lösenordet för användare "{{user}}"?',
      users_restore_password_success:
        'Ett e-postmeddelande med instruktioner har skickats till {{user}}.',
      user_restore_password_error: 'Det gick inte att återställa lösenordet',
      // Route: Users - Shared
      users_search_btn_text: 'Sök',
      users_search_btn_title: 'Sök',
      users_role_admin_label: 'Administratör',

      // Route: Users - Add Admin Panel
      users_panel_add_admin_title: 'Lägg till administratör',
      users_panel_add_admin_send_btn_text: 'Skicka inbjudan',
      users_panel_add_admin_send_btn_title: 'Skicka inbjudan',
      users_panel_add_admin_send_success: 'Inbjudan har skickats till "{{email}}".',
      users_panel_add_admin_close_btn_text: 'Stäng',
      users_panel_add_admin_close_btn_title: 'Stäng',
      users_panel_add_admin_authority_choose_btn_text: 'Välj',
      users_panel_add_admin_authority_choose_btn_title: 'Välj betalare',

      // Route: Users - Add User Panel
      users_panel_add_user_title: 'Lägg till användare',
      users_panel_add_user_send_btn_text: 'Skicka inbjudan',
      users_panel_add_user_send_btn_title: 'Skicka inbjudan',
      users_panel_add_user_send_success: 'Inbjudan har skickats till "{{email}}".',
      users_panel_add_user_close_btn_text: 'Stäng',
      users_panel_add_user_close_btn_title: 'Stäng',

      // Route: Users - Edit Admin Panel
      users_panel_edit_admin_title: 'Användare',
      users_panel_edit_admin_close_btn_text: 'Avbryt',
      users_panel_edit_admin_close_btn_title: 'Avbryt',
      users_panel_edit_admin_save_btn_text: 'Spara',
      users_panel_edit_admin_save_btn_title: 'Spara',
      users_panel_edit_admin_load_error: 'Det gick inte att ladda användaren.',
      users_panel_edit_admin_load_user_again_btn_text: 'Försök igen',
      users_panel_edit_admin_load_user_again_btn_title: 'Försök igen',
      users_panel_edit_admin_remove_user_btn_text: 'Ta bort användare',
      users_panel_edit_admin_remove_user_btn_title: 'Ta bort användaren',

      // Route: Users - Edit User Panel
      users_panel_edit_user_title: 'Användare',
      users_panel_edit_user_close_btn_text: 'Avbryt',
      users_panel_edit_user_close_btn_title: 'Avbryt',
      users_panel_edit_user_save_btn_text: 'Spara',
      users_panel_edit_user_save_btn_title: 'Spara',
      users_panel_edit_user_load_error: 'Det gick inte att ladda användaren.',
      users_panel_edit_user_load_user_again_btn_text: 'Försök igen',
      users_panel_edit_user_load_user_again_btn_title: 'Försök igen',
      users_panel_edit_user_remove_user_btn_text: 'Ta bort användare',
      users_panel_edit_user_remove_user_btn_title: 'Ta bort användaren',

      // Route: Users - Permissions Sub Panels
      users_panel_permissions_authority_heading: 'Behörighet',
      users_panel_permissions_not_selected: 'Ej valt',
      users_panel_permissions_cancel_btn_text: 'Avbryt',
      users_panel_permissions_cancel_btn_title: 'Avbryt',
      users_panel_permissions_add_btn_text: 'Lägg till',
      users_panel_permissions_add_btn_title: 'Lägg till behörighet',
      users_panel_permissions_add_more_btn_text: 'Lägg till behörighet',
      users_panel_permissions_add_more_btn_title: 'Lägg till behörighet',
      users_panel_permissions_payer_subheading: 'Betalare {{id}} {{name}}',
      users_panel_permissions_selected_utility_change_workplaces_btn_text: 'Ändra arbetsplatser',
      users_panel_permissions_selected_utility_change_workplaces_btn_title: 'Ändra arbetsplatser',
      users_panel_permissions_selected_utility_remove_btn_text: 'Ta bort behörighet',
      users_panel_permissions_selected_utility_remove_btn_title: 'Ta bort behörighet',
      users_panel_permissions_select_orderer_title: 'Beställare',
      users_panel_permissions_select_orderer_heading: 'Välj en beställare',
      users_panel_permissions_select_orderer_subheading:
        '{{count}} beställare under {{id}} {{name}}',
      users_panel_permissions_select_orderer_search_placeholder: 'Sök efter en beställare',
      users_panel_permissions_select_orderer_search_error:
        'Sökning på "{{searchQuery}}" gav ingen träff.',
      users_panel_permissions_select_orderer_list_select_btn_text: 'Välj',
      users_panel_permissions_select_orderer_list_select_btn_title: 'Välj beställare',
      users_panel_permissions_select_workplaces_heading: 'Välj en eller flera arbetsplatser',
      users_panel_permissions_select_workplaces_subheading:
        '{{count}} arbetsplatser under {{id}} {{name}}',
      users_panel_permissions_select_workplaces_search_placeholder: 'Sök efter en arbetsplats',
      users_panel_permissions_select_workplaces_search_error:
        'Sökning på "{{searchQuery}}" gav ingen träff.',
      users_panel_permissions_select_workplaces_add_btn_text: 'Välj {{count}} st arbetsplatser',
      users_panel_permissions_select_workplaces_add_btn_title: 'Välj {{count}} st arbetsplatser',
      users_panel_permissions_select_permissions_add_btn_title: 'Välj rättigheter',
      users_panel_permissions_select_permissions_add_btn_text: 'Välj rättigheter',
      users_panel_permissions_select_workplaces_checkbox_all_label: 'Välj alla arbetsplatser',
      users_panel_permissions_alert_no_results: 'Hittade inga behörigheter. Försök igen.',
      users_panel_permissions_alert_no_search_results: 'Din sökning matchar inga behörigheter.',
      // Select Permissions
      users_panel_permissions_heading_groups: 'Koncerner',
      users_panel_permissions_heading_payers: 'Betalare',
      users_panel_permissions_heading_orderers: 'Beställare',
      users_panel_permissions_heading_workplaces: 'Arbetsplatser',
      users_panel_permissions_select_search_no_results: 'Inga resultat.',
      users_panel_permissions_select_search_placeholder: 'Sök...',
      users_panel_permissions_select_search_button: 'Stäng',
      users_panel_permissions_select_permissions_select_all: 'Välj allt',

      // Route: Users - Panel Shared
      users_panel_shared_user_information_heading: 'Användarinformation',
      users_panel_shared_authority_heading: 'Behörighet',
      users_panel_shared_change_user_information_btn_text: 'Ändra',
      users_panel_shared_change_user_information_btn_title: 'Ändra användarinformation',
      users_panel_shared_change_user_information_cancel_btn_text: 'Avbryt',
      users_panel_shared_change_user_information_cancel_btn_title:
        'Avbryt ändring av användarinformation',
      users_panel_shared_change_authority_btn_text: 'Ändra',
      users_panel_shared_change_authority_btn_title: 'Ändra behörighet',
      users_panel_shared_change_authority_cancel_btn_text: 'Avbryt',
      users_panel_shared_change_authority_cancel_btn_title: 'Avbryt ändring av behörighet',
      users_panel_shared_email_link_title: 'Skicka e-post till {{email}}',
      users_panel_shared_input_fname_label: 'Förnamn',
      users_panel_shared_input_firstname_label: 'Förnamn',
      users_panel_shared_input_firstName_label: 'Förnamn',
      users_panel_shared_input_lname_label: 'Efternamn',
      users_panel_shared_input_lastname_label: 'Efternamn',
      users_panel_shared_input_lastName_label: 'Efternamn',
      users_panel_shared_input_email_label: 'E-postadress',
      users_panel_shared_input_phone_label: 'Telefonnummer',
      users_panel_shared_send_error: 'Det gick inte att skicka inbjudan. Försök igen.',
      users_panel_shared_update_success: 'Användaren har uppdaterats.',
      users_panel_shared_created_label: 'Användare skapad',
      users_panel_shared_last_online_label: 'Senast inloggad',
      users_panel_shared_authority_payer_label: 'Betalare',
      users_panel_shared_authority_error: 'Du måste välja en behörighet.',
      users_panel_shared_search_customer_placeholder: 'Sök efter ett kundnummer',
      users_panel_shared_input_required_error: 'Detta fält får inte vara tomt.',
      users_panel_shared_input_required_format_error: 'Ej giltig e-postaddress.',
      users_panel_shared_remove_user_btn_text: 'Ta bort användare',
      users_panel_shared_remove_user_btn_title: 'Ta bort användare',
      users_panel_shared_remove_user_success: 'Användaren har raderats',

      users_panel_shared_search_authority_title: 'Sök behörighet',
      users_panel_shared_close_search_authority_title: 'Stäng sök',
      users_panel_shared_close_search_authority_text: 'Stäng sök',
      users_panel_shared_search_authority_input_placeholder: 'Sök behörighet',

      // Route: Users - Table
      users_table_cell_heading_user: 'Användare',
      users_table_cell_heading_role: 'Roll',
      users_table_cell_heading_customer: 'Kund',
      users_table_cell_heading_activated: 'Aktiverad',
      users_table_cell_heading_created: 'Skapad',
      users_table_cell_heading_last_login: 'Senast inloggad',
      users_table_activated_true: 'Ja',
      users_table_activated_false: 'Nej',
      users_table_utility_menu_open_btn_title: 'Visa fler alternativ',
      users_table_utility_menu_details_btn_text: 'Visa detaljer',
      users_table_utility_menu_details_btn_title: 'Visa detaljer',
      users_table_utility_menu_impersonate_btn_text: 'Spegla användare',
      users_table_utility_menu_impersonate_btn_title: 'Spegla användare',
      users_table_utility_menu_remove_user_btn_text: 'Ta bort användare',
      users_table_utility_menu_remove_user_btn_title: 'Ta bort användaren',
      users_table_utility_menu_reset_password_btn_text: 'Återställ lösenord',
      users_table_utility_menu_reset_password_btn_title: 'Återställ lösenord',
      users_table_utility_menu_enable_btn_text: 'Aktivera användare',
      users_table_utility_menu_enable_btn_title: 'Aktivera användare',
      users_table_utility_menu_disable_btn_text: 'Inaktivera användare',
      users_table_utility_menu_disable_btn_title: 'Inaktivera användare',
      users_table_no_results: 'Kunde inte hitta några användare.',
      users_table_impersonate_modal_title: 'Vill du spegla användare?',
      users_table_impersonate_modal_description:
        'Du kommer att se portalen med denna användares behörighet.',
      users_table_impersonate_modal_button_start_text: 'Starta spegling',
      users_table_impersonate_modal_button_start_title: 'Starta spegling',
      users_table_impersonate_modal_button_cancel_text: 'Avbryt',
      users_table_impersonate_modal_button_cancel_title: 'Avbryt spegling',

      // Route: Users - User Admin
      users_admin_customers_show_users_btn_title: 'Visa användare för {{customer}}',
      users_admin_customers_user_list_heading: 'Visar användare för {{customer}}',
      users_admin_customers_user_list_back_btn_text: 'Tillbaka till listan',
      users_admin_customers_user_list_back_btn_title: 'Tillbaka till listan',
      users_admin_customers_user_list_user_count: '{{count}} användare',
      users_admin_customers_user_list_role_default: 'Användare',
      users_admin_customers_user_list_no_information: 'Uppgift saknas',
      users_admin_customers_user_list_error: 'Något blev fel. Försök igen.',

      users_admin_search_help:
        'Du kan söka på ett organisationsnummer, kundnummer, e-postadress eller namn.',
      users_admin_search_query: 'Visar resultat för “{{query}}”',

      // Route: Terms Of Use
      termsofuse_title: 'Användarvillkor',
      termsofuse_h1: 'Användarvillkor',

      // Route: Privacy Policy
      privacypolicy_title: 'Integritetspolicy',
      privacypolicy_h1: 'Integritetspolicy',

      // Route: Register User
      register_user_title: 'Skapa ett konto på Remondis kundportal',
      register_user_h1: 'Skapa ett konto på Remondis kundportal',
      register_user_ingress:
        'Ange dina uppgifter nedan för att skicka en konto-förfrågan för kundportalen.',
      register_user_disclaimer:
        'Observera att det kan ta upp till 48 timmar innan ditt konto är aktiverat.',
      register_user_integrity_policy_text:
        'När du skapar ett användarkonto accepterar du våra användarvillkor. Läs vår',
      register_user_existing_account: 'Har du redan ett Remondis-konto?',
      register_user_form_information: 'Användarinformation',
      register_user_form_permissions: 'Behörighet',
      register_user_form_field_firstname: 'Förnamn*',
      register_user_form_field_lastname: 'Efternamn*',
      register_user_form_field_email: 'Epost*',
      register_user_form_field_phone: 'Telefonnummer',
      register_user_form_field_customer_number: 'Kundnummer hos Remondis*',
      register_user_form_field_customer_number_error: 'Kundnummer får bara innehålla siffor',
      register_user_form_field_corporate_id: 'Organisationsnummer*',
      register_user_form_field_corporate_id_error:
        'Ange ett giltligt organisationsnummer (t.ex. 111111-2222)',
      register_user_form_submission_button: 'Skapa konto',
      register_user_form_submission_success:
        'Din förfrågan har skickats till Remondis kundtjänst. Behövs mer information kommer du att bli kontaktad. Efter att ditt konto är skapat kommer du att få ett mail där du ombeds uppdatera ditt lösenord.',
      register_user_form_submission_error: 'Ett fel har inträffat',

      // Route: Services
      services_title: 'Tjänster',
      services_h1: 'Beställ tömning',
      services_description:
        'Alla era aktiva tjänster och behållare med eventuella planerade tömningar. Önskar ni boka en tömning på era befintliga behållare? Klicka på tjänsten i listan nedan och boka direkt. Vill ni lägga till en ny hämtning eller behållare kan ni göra det på knappen Köp ny tjänst.',
      services_description_link_title: 'Kontakta oss via e-post',
      services_details_panel_accordion_details_title: 'Detaljer',
      services_details_panel_accordion_epmtying_title: 'Tömningshistorik',
      services_details_panel_workplace_label: 'Arbetsplats',
      services_details_panel_waste_label: 'Innehåll',
      services_details_panel_container_label: 'Behållare',
      services_details_panel_marking_label: 'Märkning',
      services_details_panel_container_emptying_next_label: 'Kommande',
      services_details_panel_container_emptying_last_label: 'Senaste',
      services_grid_count_unknown: 'Okänt antal',
      services_grid_count_suffix: 'tjänster',
      services_fetch_data_error: 'Kunde inte hämta data. Försök igen.',
      services_grid_no_results_alert: 'Kunde inte hitta några tjänster.',
      services_card_show_more_information_button_title: 'Visa mer information',
      services_card_image_missing_alt: 'Bild saknas för {{container}}',
      services_card_next_emptying: 'Planerad tömning',
      services_card_last_emptying: 'Senaste tömning',
      services_card_unknown_waste: 'Okänt innehåll',
      services_card_unknown_container: 'Övrigt',
      services_add_filter_btn_text: 'Lägg till filter',
      services_add_filter_btn_title: 'Lägg till filter',
      services_filter_btn_edit_title: 'Ändra filter',
      services_filter_btn_clear_title: 'Rensa filter',
      services_filter_panel_title: 'Filter',
      services_filter_panel_clear_btn_text: 'Rensa alla filter',
      services_filter_panel_clear_btn_title: 'Rensa alla aktiva filter',
      services_filter_panel_apply_btn_text: 'Spara',
      services_filter_panel_apply_btn_title: 'Spara',
      services_filter_panel_search_placeholder: 'Sök...',
      services_filter_panel_tab_add_filter_text: 'Lägg till nytt filter',
      services_filter_panel_tab_add_filter_title: 'Lägg till nytt filter',
      services_filter_panel_tab_saved_filter_text: 'Mallar',
      services_filter_panel_tab_saved_filter_title: 'Visa mallar',
      services_save_filter_btn_text: 'Spara mall',
      services_save_filter_btn_title: 'Spara aktiva filter som en mall',
      services_save_filter_panel_title: 'Spara mall',
      services_save_filter_panel_save_btn_text: 'Spara',
      services_save_filter_panel_save_btn_title: 'Spara mall',
      services_save_filter_panel_description:
        'Spara din nuvarande filtrering som en mall. Den går sedan att komma åt via {{savedFilterBtn}}.',
      services_save_filter_panel_input_name_label: 'Namn på mallen',
      services_save_filter_panel_input_name_error:
        'För att kunna spara mallen krävs det att du anger ett namn.',
      services_saved_filter_panel_no_results:
        'Du har inga sparade mallar. För att spara en mall - gör en filtrering och klicka sedan på {{saveFilterBtn}}.',
      services_saved_filter_panel_remove_confirm:
        'Är du säker på att du vill ta bort filtret "{{filterName}}"?',
      services_saved_filter_panel_select_btn_text: 'Applicera mall',
      services_saved_filter_panel_select_btn_title: 'Applicera mall "{{filterName}}"',
      services_saved_filter_panel_remove_btn_text: 'Ta bort mall',
      services_saved_filter_panel_remove_btn_title: 'Ta bort mallen "{{filterName}}"',
      services_saved_filter_panel_rename_btn_text: 'Ändra namn',
      services_saved_filter_panel_rename_btn_title: 'Ändra namn på mallen "{{filterName}}"',
      services_saved_filter_panel_box_menu_btn_title: 'Visa fler alternativ',
      services_saved_filter_panel_update_success: 'Mall sparad.',
      services_saved_filter_panel_update_error: 'Något gick fel, försök igen',
      services_new: 'Tömning beställd',
      services_new_service_panel_title: 'Köp ny tjänst',
      services_new_service_panel_text:
        'Behöver ni hjälp med utökad återvinning? Fyll i  formuläret nedan så kontaktar vi er. En tydlig beskrivning ger ofta en snabbare och mer precis handläggning.',
      services_new_service_panel_abort: 'Avbryt',
      services_new_service_panel_send: 'Skicka förfrågan',
      services_new_service_panel_workplace_placeholder: 'Välj arbetsplats',
      services_new_service_panel_workplace_missing: 'Saknas din arbetsplats?',
      services_new_service_panel_new_workplace_placeholder: 'Fyll i address',
      services_new_service_panel_container_placeholder: 'Välj behållare',
      services_new_service_panel_material_placeholder: 'Välj material',
      services_new_service_panel_emptying_placeholder: 'Önskat tömningsintervall',
      services_new_service_panel_comment_placeholder: 'Ev. kommentar',
      services_new_service_panel_success: 'Vi har tagit emot din förfrågan',
      services_new_service_panel_close: 'Stäng',
      services_new_service_panel_something_went_wrong: 'Något gick fel',
      services_new_service_panel_try_again: 'Försök igen',
      services_new_service_panel_request_recieved_title: 'Vi har tagit emot din förfrågan',
      services_new_service_panel_request_recieved_text:
        'Din beställning kommer att skickas till vår kundtjänst som kommer att hantera ärendet.',
      services_new_service_panel_request_recieved_subtitle: 'Din förfrågan:',
      layout_list: 'Lista',
      layout_grid: 'Rutnät',
      services_sorting_guides_title: 'Sorteringsguider',
      services_sorting_guides_description:
        'Lär dig sortera avfall korrekt med våra Sorteringsguider för olika material – ladda ner PDF:erna här.',
      services_sorting_guides_download_pdf: 'Ladda ner PDF',
      services_sorting_guides_filename_header: 'Filnamn',

      // Route: Not Found (404)
      not_found_title: '404 Hittades inte',
      not_found_h1: '404 Hittades inte',
      not_found_description: 'Sidan du söker hittades inte',

      // Comopnent: Terms Agreement Modal
      terms_agreement_modal_heading: 'Användarvillkor',
      terms_agreement_modal_title: 'Bekräfta användarvillkoren',
      terms_agreement_modal_text:
        'För att använda Remondis Sweden ABs kundportal och app behöver du bekräfta att du läst våra användarvillkor.',
      terms_agreement_modal_read_more: 'Läs användarvillkor',
      terms_agreement_modal_button_text: 'Jag godkänner användarvillkoren',

      // Comopnent: Header
      header_logotype_alt: 'Logotyp',
      header_login_button: 'Logga in',
      header_register_user_button: 'Registrera användare',
      header_profile_btn_title: 'Visa profil',
      header_profile_panel_title: 'Profil',
      header_profile_panel_authority_heading: 'Behörighet',
      header_profile_panel_authority_payer_label: 'Betalare',
      header_profile_panel_authority_orderer_label: 'Beställare',
      header_profile_panel_authority_workplaces_label: 'Arbetsplatser',
      header_profile_panel_signout_btn_text: 'Logga ut',
      header_profile_panel_signout_btn_title: 'Logga ut från portalen',
      header_profile_form_success: 'Telefonnummer uppdaterat',
      header_profile_form_error: 'Något gick fel, försök igen',
      header_mobile_nav_heading: 'Meny',
      header_mobile_nav_open_btn_title: 'Öppna menyn',
      header_mobile_nav_close_btn_title: 'Stäng menyn',

      // Component: Loader
      loader_screenreader_text: 'Laddar...',

      // Component: Badge
      badge_close_btn_title: 'Ta bort',

      // Component: Panel
      panel_close_btn_title: 'Stäng panel',
      panel_sub_close_btn_title: 'Stäng dialog',
      panel_sub_back_btn_title: 'Tillbaka',

      // Component: Pagination
      pagination_btn_title: 'Visa sida',
      pagination_settings_count_start: 'Visa',
      pagination_settings_count_end: 'resultat per sida',
      pagination_settings_count_select_label: 'Välj hur många resultat som ska visas per sida.',

      // Component: Menu Foldout
      menu_foldout_close_foldout_open_btn_title: 'Öppna menyn',
      menu_foldout_close_foldout_close_btn_title: 'Stäng menyn',

      // Component: Foldout
      foldout_close_btn_title: 'Stäng menyn',

      // Component: Details
      details_summary_title: 'Visa detaljer',

      // Component: Product Card
      product_card_onclick_title_fallback: 'Beställ tömning',
      product_card_badge_missing: 'Senast tömning saknas',

      // Component: Order Service
      order_service_heading: 'Beställ ny tömning',
      order_service_description:
        'Beställning av hemtag kan inte göras i portalen. Kontakta kundtjänst om ni vill beställa hemtag.',
      order_service_form_phone_placeholder: 'Telefonnummer',
      order_service_form_phone_label: 'Telefonnumret är hämtat från dina profilinställningar.',
      order_service_form_date_label: 'Välj önskat tömningsdatum',
      order_service_form_date_option_placeholder: 'Välj önskat tömningsdatum',
      order_service_form_date_option_asap: 'Snarast möjligt',
      order_service_form_date_option_custom: 'Välj datum',
      order_service_form_message_placeholder: 'Övriga upplysningar',
      order_service_form_message_label:
        'Ange eventuella övriga upplysningar eller information till chauffören.',
      order_service_form_mandatory_amount_message_label:
        'Ange antal balar/pallar eller annan relevant information',
      order_service_form_mandatory_amount_message_placeholder:
        'Ange antal balar/pallar eller annan relevant information',
      order_service_form_success: 'Beställning skickad!',
      order_service_form_submit: 'Skicka beställning',
      order_service_form_error: 'Något gick fel, försök igen senare',

      // Scheduled services
      scheduled_services: 'Planerade tömningar',
      see_all_scheduled_services_by_workplaces:
        'Få en överblick av planerade tömningar på era arbetsplatser.',
      see_all_scheduled_services_by_workplace: 'Se alla planerade tömningar på er arbetsplats.',
      number_of_services: 'Antal tömningar',
      today: 'Idag',
      last_week: 'Föregående vecka',
      next_week: 'Nästa vecka',
      no_scheduled_service: 'Inga planerade tömningar',
      go_to_current_week: 'Gå till nuvarande vecka',
      all_scheduled_services_by: 'Se alla tömningar den',
      services_container_missing_name: 'Remondis Behållare',

      // Route: Co2
      trend_co2_title: 'CO₂-påverkan',
      trend_co2_description:
        'Analysera er klimatpåverkan utifrån ett livscykelperspektiv. Modellen innebär att koldioxidavtrycket för utvinning, bearbetning och produktion av ny råvara har beräknats för primär och sekundär materialproduktion för att på så sätt kunna visa på den mängd CO2-utsläpp som kan undvikas genom återvinning. Resultatet presenteras i graferna nedan där negativa CO₂-utsläpp indikerar klimatnytta och positiva indikerar klimatpåverkan.',
      trend_co2_saving: 'CO₂ besparing',
      trend_co2_wasted: 'CO₂ belastning',
      trend_co2_saving_info: 'Negativa CO₂ utsläpp indikerar klimatnytta',
      trend_co2_wasted_info: 'Positiva CO₂ utsläpp indikerar klimatpåverkan',
      co2_total_prev_month: 'Er totala CO₂-påverkan föregående månad',
      total_co2: 'Er totala CO₂-påverkan',
      saving_negaitve: 'Ajdå! Ni har under den valda perioden en högre belastning än besparing.',

      // Route: Workplace Analysis
      workplace_analysis_title: 'Arbetsplatsanalys',
      workplace_analysis_h1: 'Arbetsplatsanalys',
      workplace_analysis_content:
        'Analysera er avfallssortering uppdelat per arbetsplats. Hitta eventuella avvikelser och identifiera förbättringspotential med hjälp av rapporten nedan. Sträva alltid efter att ha så hög procentuell andel som möjligt i avfallstrappans övre nivåer. Rapporten går att ladda ner som Excel eller CSV. ',

      // Workplace Analysis: Table Columns
      workplace_analysis_header_name: 'Arbetsplats',
      workplace_analysis_header_address: 'Adress',
      workplace_analysis_header_reuse: 'Återanvändning',
      workplace_analysis_header_recycle: 'Återvinning',
      workplace_analysis_header_biological: 'Biologisk behandling',
      workplace_analysis_header_energy: 'Energiutvinning',
      workplace_analysis_header_landfill: 'Deponering',
      workplace_analysis_header_other: 'Okategoriserat',

      // Route: My pages
      my_pages: 'Mina sidor',
      my_pages_about: 'Om dig',
      my_pages_your_information: 'Dina person- och kontaktuppgifter',
      my_pages_contact_information: 'Person- och kontaktuppgifter',
      my_pages_contact: 'Kontaktuppgifter',
      my_pages_premissions: 'Behörighet',
      my_pages_your_premissions: 'Se din behörighet',
      name: 'Namn',
      mail: 'E-postadress',
      phone: 'Telefonnummer',
      add_number: 'Lägg till nummer',
      save: 'Spara',
      toggle_profile: 'Öppna/stäng profilmeny',

      // General translations
      read_more: 'Läs mer',
      see_details: 'Se detaljer',
      close: 'Stäng',
      password: 'Lösenord',
      search: 'Sök',
      search_remove: 'Ta bort sök',
      show_details: 'Visa detaljer',
      no_result: 'Inga resultat',
      abort: 'Avbryt',
      account: 'Konto',

      // API Clients
      api_client: 'API-konton',
      api_client_preamble: 'Här listas och hanterar ni API-konton för portalen.',
      api_client_add: 'Lägg till API-konto',
      api_client_revoke: 'Avaktivera',
      api_client_revoke_error: 'Vi kunde tyvärr inte avaktivera kontot, försök igen',
      api_client_revoke_confirm: 'Är du säker på att du vill avaktivera kontot',
      api_client_search: 'Sök efter API-konto',
      api_client_active: 'Aktiv',
      api_client_revoked: 'Ej aktiv',
      api_client_update: 'Uppdatera konto',
      api_client_created_account: 'API-konto har skapats',
      api_client_pwd_copied: 'Lösenordet är nu kopierat och ligger i dina utklipp',
      api_client_pwd_reminder:
        'Spara lösenordet och ange det när kontot ansluts. Lösenordet går inte att se igen.',
      api_client_info: 'Kontoinformation',
      api_client_description: 'Beskrivning av kontot',
      api_client_description_info:
        'Ge API-kontot en beskrivning så att ni enklare kan identifiera vart det används',
      api_client_create_description_reminder:
        'För att skapa API-kontot så behöver du uppge en beskrivning.',
      api_client_update_description_reminder:
        'För att updatera API-kontot så behöver du uppge en beskrivning.',
      api_client_change_error: 'Ajdå, något verkar ha gått fel. Försök gärna igen.',
      api_client_status: 'Status',
      api_client_created: 'Skapad',
      api_client_created_by: 'Skapad av',
      api_client_used: 'Senast använd',
      api_client_update_account: 'Uppdatera API-konto',
      api_client_update_success: 'Ditt konto har nu uppdaterats!',
      api_client_delete: 'Ta bort kontot',
      api_client_delete_confirm: 'Är du säker på att du vill ta bort kontot',
      api_client_delete_confirm_error: 'Kan inte ta bort ett aktivt konto',
      api_client_delete_error: 'Vi kunde tyvärr inte ta bort kontot, försök igen',

      // Filter
      filter_panel_title: 'Filter',
      filter_panel_no_filters_results_alert: 'Inga filters.',
      filter_panel_save: 'Spara',
      filter_panel_clear_all_btn_text: 'Rensa alla filter',
      filter_panel_clear_current_btn_text: 'Rensa filter',
      filter_panel_show_all_btn_text: 'Visa alla',
      filter_panel_show_less_btn_text: 'Visa färre',
      filter_current_month: 'Nuvarande månad',
      filter_label_workplaces: 'Arbetsplatser',
      filter_label_materials: 'Avfall',
      filter_label_materialFilters: 'Avfall',
      filter_label_waste: 'Avfall',
      filter_label_containertypes: 'Behållare',
      filter_label_customer: 'Kund',
      filter_label_month: 'Månad',
      filter_label_date: 'Datum',
      filter_label_currentMonth: 'Denna månad',
      filter_label_lastMonth: 'Föregående månad',
      filter_label_previous_month: 'föregående månad',
      filter_label_lastTwelveMonths: 'Senaste 12 månaderna',
      filter_label_last_twelve_months: 'senaste 12 månaderna',
      filter_label_thisYear: 'Detta år',
      filter_label_this_year: 'detta året',
      filter_label_lastYear: 'Senaste året',
      filter_label_custom: 'Välj period',
      filter_label_boolean_deviation: 'Visa endast avvikelser',
      filter_label_hazardousWaste: 'Avfall',
      filter_label_articleFilters: 'Artikel',
      filter_label_articleTypeFilters: 'Artikeltyp',
      filter_label_materialTypeFilters: 'Avfallstyp',
      filter_label_customerFilters: 'Kunder',
      filter_label_workplaceFilters: 'Arbetsplatser',
      filter_label_estates: 'Fastigheter',
      filter_compare_total: 'Ingen jämförelse',
      filter_compare_month: 'Jämför månadsvis',
      filter_compare_comparePeriod: 'Jämför med samma period föregående år',
      filter_label_estateFilters: 'Fastigheter',
      sort_label_default: 'Sortera',
      sort_label_workplace: 'Arbetsplatser',
      sort_label_workplaces: 'Arbetsplatser',
      sort_label_article: 'Avfall',
      sort_label_reuse: 'Återanvändning',
      sort_label_recycle: 'Återvinning',
      sort_label_energy: 'Energiutvinning',
      sort_label_landfill: 'Deponering',
      sort_label_scheduled_collections: 'Tömningar',
      sort_string_asc: 'A-Ö',
      sort_string_desc: 'Ö-A',
      sort_number_asc: 'Stigande',
      sort_number_desc: 'Fallande',
      unknown: 'Okänt värde',

      fetch_document_error: 'Något gick fel vid hämtning av dokument',

      // Route: Invoices
      invoices_title: 'Fakturor',
      invoices_description: 'Här listas och hanterar ni era fakturor.',
      invoices_unpaid: 'Obetalda',
      invoices_paid: 'Betalda',
      invoices_no_results: 'Kunde inte hitta några fakturor.',
      invoice: 'Faktura',
      invoice_see_invoice: 'Se fakturan',
      invoice_download: 'Ladda ner',
      invoice_document_error: 'Kunde inte hämta fakturadokumentet. Försök igen.',
      invoice_due_date: 'Förfallodatum',
      invoice_paid: 'Betald',
      invoice_pay_before: 'Betalas senast',

      // Table Columns
      invoices_header_invoiceDate: 'Fakturadatum',
      invoices_header_invoiceNumber: 'Fakturanummer',
      invoices_header_customerName: 'Kundnamn',
      invoices_header_customerNumber: 'Kundnummer',
      invoices_header_amount: 'Belopp',
      invoices_header_invoiceType: 'Fakturatyp',
      invoices_header_dueDate: 'Förfallodatum',
      invoices_header_paymentStatus: 'Betalningsstatus',
      invoices_header_download: 'Faktura',

      // Route: Workplaces
      workplaces_title: 'Dina arbetsplatser',
      workplaces_description:
        'Se de arbetsplatser du har tillgång till och få en översikt över dess aktiva tjänster och behållare. Ladda ner en pdf med en QR-kod som ni kan fästa vid behållarna. När koden skannas visas alla planerade tömningar för den specifika arbetsplatsen.',
      workplaces_table_header_workplaces: 'Arbetsplatser',
      workplaces_table_header_services: 'Tjänster',
      workplaces_table_header_scheduled: 'Tömningar',
      workplaces_table_services_one: '{{count}} tjänst',
      workplaces_table_services_other: '{{count}} tjänster',
      workplaces_table_view_services: 'Se tjänster',
      workplaces_table_scheduled_one: '{{count}} planerad tömning',
      workplaces_table_scheduled_other: '{{count}} planerade tömningar',
      workplaces_table_view_scheduled: 'Se tömningar',
      workplaces_table_download_qr: 'Ladda ner QR för tömningsschema',
      workplaces_no_workplaces_found: 'Kunde inte hitta några arbetsplatser.',
      workplaces_fetch_data_error: 'Kunde inte hämta data. Försök igen.',
      workplaces_download_all_qr: 'Ladda ner alla QR-koder',
      workplaces_download_chosen_qr: 'Ladda ner QR-koder för valda arbetsplatser',
      workplaces_workplace_analysis_widget:
        'Vill ni lära er mer om hur era arbetsplatser presterar enligt avfallstrappans fem nivåer? Med hjälp av vår arbetsplatsanalys kan ni identifiera eventuella avvikelser och förbättringspotential.',
      workplaces_workplace_analysis_widget_link: 'Till arbetsplatsanalysen',

      // Widgets

      widget_scheduled_count_one: '{{count}} planerad tömning idag',
      widget_scheduled_count_other: '{{count}} planerade tömningar idag',
      widget_scheduled_loading: 'Hämtar planerade tömningar...',
      widget_scheduled_error: 'Kunde inte hämta planerade tömningar',
      widget_scheduled_see_all: 'Se alla tömningar',
      widget_services_title: 'Beställ ny tömning',
      widget_services_description:
        'Se alla era aktiva tjänster med eventuella planerade tömningar och beställ nya tömningar direkt i portalen.',
      widget_services_link_text: 'Gå till Beställ tömning',
      widget_waste_hierarchy_title: 'Avfallstrappan',
      widget_waste_hierarchy_description:
        'Analysera er avfallssortering enligt avfallstrappans fem nivåer och identifiera eventuella avvikelser med hjälp av graferna.',
      widget_waste_hierarchy_link_text: 'Se fördelning',

      // UI
      download_document_error: 'Något gick fel vid hämtning av dokument',

      // Trends
      sustainability_report: 'Miljörapport',
      sustainability_description:
        'Analysera behandlingen av erat avfall baserat på olika avfallstyper med vår miljörapport. Rapporten är tillgänglig för nedladdning i både Excel- och CSV-format.',
      article: 'Avfall',
      reuse: 'Återanvändning',
      recycle: 'Återvinning',
      biological: 'Biologisk behandling',
      energy: 'Energiutvinning',
      landfill: 'Deponering',
      other: 'Okategoriserat',
      ewc: 'EWC-kod',
      rd: 'R&D-kod',
      details: 'Detaljer',
      date: 'Datum',
      orderNumber: 'Ordernummer',
      workplaceName: 'Arbetsplatsnamn',
      workplaceAddress: 'Adress',
      status: 'Status',
      materials: 'Artikel',

      st: '{{count}} st',

      order_history: 'Orderhistorik',
      order_history_description:
        'Se er orderhistorik och hitta eventuella avvikelser med hjälp av tabellen nedan.',
      order_history_deviations: 'Avvikelser',
      order_history_fulfilled_orders: 'Utförda ordrar',
      order_history_total_orders: 'Totala ordrar',
      order_history_orders_one: '{{count}} order',
      order_history_orders_other: '{{count}} ordrar',
      order_history_deviation: 'Avvikelse',
      order_history_no_orders_found: 'Kunde inte hitta några ordrar.',
      order_history_fetch_data_error: 'Kunde inte hämta data. Försök igen.',
      order_history_deviation_code: 'Avvikelsekod',
      order_history_deviation_wrong_code: 'Fel kod',
      order_history_deviation_code_wrong: 'Kod fel',
      order_history_deviation_slippery: 'För halt',
      order_history_deviation_heavy: 'För tungt',
      order_history_deviation_no_room: 'Stängt, ingen plats',
      order_history_deviation_blocked: 'Blockerat',
      order_history_deviation_not_executed: 'Ej utkört',
      order_history_deviation_locked: 'Låst',
      order_history_deviation_incorrect_sorting: 'Felsorterat i behållare',
      order_history_deviation_contact: '<Link>Kontakta kundtjänst</Link> för eventuella frågor',
      order_history_order_details: 'Detaljer',
      order_history_export_heading: 'Exportera orderhistorik',
      order_history_env_dry_cleaning: 'Miljörumstvätt',
      order_history_env_guard: 'Miljörumsvärd',
      order_history_maintenance: 'Underhåll/skötsel av soprum',
      order_history_vessel_cleaning: 'Tvätt av kärl',

      scope3_title: 'Klimatpåverkan',
      scope3_description:
        'Scope 3, CO₂e -påverkan som uppstår genom avfallshantering, inkluderar klimatpåverkan från avfallshantering, förberedelse inför återvinning och transporter kopplade till avfallshanteringen. Scope 4 är ett inofficiellt begrepp för undvikna utsläpp genom återvinning jämfört med tillverkning av nytt (jungfruligt) material (eller i form av el och värme som ersätter energiproduktion baserat på jungfruliga källor).',
      scope3_description_short:
        'Scope 3, CO₂e -påverkan som uppstår genom avfallshantering, inkluderar klimatpåverkan från avfallshantering, förberedelse inför återvinning och transporter kopplade till avfallshanteringen.',
      scope3_read_more: 'Läs mer om er klimatpåverkan här',
      scope3_handled_waste: 'Mängd hanterat avfall (kg)',
      scope3_scope3_modal_title: 'Scope 3',
      scope3_scope3_modal: `Scope 3, CO₂e -påverkan som uppstår genom avfallshantering, inkluderar klimatpåverkan från avfallshantering, förberedelse inför återvinning och transporter kopplade till avfallshanteringen. Beräkningarna är gjorda utifrån avfallsslagsspecifikberäkning (<Link>waste-type-specific</Link>). Utsläppsfaktorerna används för att bestämma kg CO₂e -utsläpp per mängd av respektive avfallsslag, vilket multipliceras med mängden hanterat avfall av respektive slag. Vid kartläggning av företagets växthusgasutsläpp enligt GHG-protokollet och dess Scope 3 får inga undvikna utsläpp eller utsläppsbesparingar genom återvinning redovisas i Scope-beräkningarna.

      <strong>Koldioxidekvivalenter</strong>
      CO₂e eller koldioxidekvivalenter är ett mått som används för att mäta växthusgasutsläpp. Eftersom olika växthusgaser är olika potenta omräknas varje växthusgas till CO₂e för att kunna möjliggöra jämförelser. Det är alltså inte enbart CO₂-utsläpp som ingår i beräkningen utan även andra växthusgaser som t.ex metangas.

      <strong>GHG-protokollet</strong>
      Greenhouse Gas Protcol är ett omfattande globalt standardiserat ramverk som mäter och förvaltar växthusgas-utsläpp från privata och offentliga sektorer, värdekedjor och utsläppsminskningar. Globalt sett är GHG-protokollet den mest använda redovisningsstandarden för växthusgasutsläpp.
      `,
      scope3_scope3_modal_read_more: 'Läs mer om Scope 3',
      scope3_scope4_modal_title: 'Scope 4',
      scope3_scope4_modal: `Scope 4 är ett inofficiellt begrepp för undvikna utsläpp genom återvinning jämfört med tillverkning av nytt (jungfruligt) material (eller i form av el och värme som ersätter energiproduktion baserat på jungfruliga källor).
      
      Scope 4 visar den sammanlagda besparingen av CO₂e -utsläpp som material- och energiåtervinning av insamlade volymer bidragit till i jämförelse med användningen av jungfruligt material (nytt). Mängd av respektive avfallsslag multipliceras med en utsläppsfaktor, även kallad emissionsfaktor. Faktorn är skapad genom jämförelse mellan utsläpp från tillverkning av jungfruligt material och utsläpp från tillverkning av återvunnet material. Samt genom jämförelse mellan energiåtervinning och annan produktion av el och värme. Resultatet blir mängd CO₂e-utsläpp som undviks genom materialåtervinning (eller i form av el och värme som ersätter energiproduktion baserat på jungfruliga källor).`,
      scope3_scope4_modal_read_more: 'Läs mer om Scope 4',
      scope3_graph_total: 'Er totala klimatpåverkan enligt Scope 3 är {{total}} kg CO₂e',
      scope3_missing_values: `<strong>Saknas det värden?</strong>
      Vi kan ännu inte beräkna er klimatpåverkan för alla typer av avfall, därför blir vissa avfallstyper i grafen tomma trots att ni har avfallshistorik för avfallet under den valda perioden.`,
      scope3_reference: `<strong>Källor/Referenser</strong>
      Avfall Sverige. (2023). Klimatpåverkan från olika avfallsfraktioner. 2023:01.
      
      Department for Energy Security & Net Zero. (2023). 2023 Government Greenhouse Gas Conversion Factors for Company Reporting, Methodology Paper for Conversion Factors Final Report.

      Environmental Product Declaration Library (EPD). Medeltal på ett antal rapporter. Databasen: EDP 
      
      IVL Svenska Miljöinstitutet. Miliute-Plepiene, J, Usbo, H. och Sundqvist J.O. (2022). Klimatnyttan med materialåtervinning av byggavfall. C694 
      
      IVL Svenska Miljöinstitutet. Ryberg Henriksson, A. (2016). Produktval av tappvattenarmaturer, kopplingar, ventiler, böjar och T-stycken för dricksvattentillämpningar tillverkade i blyinnehållande kopparlegeringar. B2259. 
      
      Värdena ska ses som komplement till andra beräkningar och REMONDIS Sweden AB tar inget ansvar för hur värdena används. REMONDIS Sweden AB är därmed inte ansvarig för skada, av vilket slag som helst, såväl direkt som indirekt, som kan uppkomma på grund av att en Kund eller tredje man använt sig av värdena som REMONDIS Sweden AB tagit fram baserade på ovan redovisade rapporter.`,

      estates_scope3_title: 'Klimatpåverkan per fastighet',
      estates_scope3_description_short:
        'Analysera CO2e-påverkan från avfallshantering i era fastigheter. Detta ger en överblick av klimatpåverkan från allt avfall som genererats både av era hyresgäster och er själva, sammanställt per fastighet.',
      estates_scope3_description:
        'Analysera CO2e-påverkan från avfallshantering i era fastigheter. Detta ger en överblick av klimatpåverkan från allt avfall som genererats både av era hyresgäster och er själva, sammanställt per fastighet. Scope 3, CO₂e-påverkan som uppstår genom avfallshantering, inkluderar klimatpåverkan från avfallshantering, förberedelse inför återvinning och transporter kopplade till avfallshanteringen. Scope 4 är ett inofficiellt begrepp för undvikna utsläpp genom återvinning jämfört med tillverkning av nytt (jungfruligt) material eller i form av el och värme som ersätter energiproduktion baserat på jungfruliga källor.',
      estates_scope3_missing_values: `<strong>OBS! Vi saknar värden för att beräkna er klimatpåverkan</strong>
        Vi kan ännu inte beräkna er klimatpåverkan för alla typer av avfall, därför blir vissa avfallstyper i grafen tomma trots att ni har avfallshistorik för avfallet under den valda perioden.`,

      user_admins_title: 'Remondis administratörer',
      user_admins_description: 'Här listas och hanterar ni Remondis administratörer för portalen.',
      user_admins_create_success: 'Administratör skapad',
      user_admins_create_success_send: `Inbjudan har skickats till <strong>{{email}}</strong>`,
      user_admins_create_success_reset: 'OK',
      user_admins_create_error: 'Något gick fel',
      user_admins_create_error_description: `Användaren <strong>{{firstName}} {{lastName}}</strong> har inte skapats`,
      user_admins_create_error_help:
        'Behöver du hjälp? <Link>Kontakta kundtjänst för portalen</Link>',
      user_admins_no_result: 'Kunde inte hitta några administratörer.',
      user_admins_fetch_data_error: 'Kunde inte hämta data. Försök igen.',
      user_admins_search: 'Sök efter administratör',
      user_admins_search_help: 'Du kan söka på e-postadress eller namn',
      user_admins_search_no_results:
        'Kunde inte hitta några administratörer som matchar din sökning.',

      tooltip_source: 'Källa',

      standard_statistics_header_date: 'Datum',
      standard_statistics_header_orderNo: 'Ordernr',
      standard_statistics_header_invoiceNo: 'Fakturanr',
      standard_statistics_header_articleNumber: 'Artikelnummer',
      standard_statistics_header_article: 'Artikel',
      standard_statistics_header_description: 'Beskrivning',
      standard_statistics_header_customerNumber: 'Kundnummer',
      standard_statistics_header_customerName: 'Kundnamn',
      standard_statistics_header_workplaceNumber: 'Arbetsplatsnummer',
      standard_statistics_header_workplaceName: 'Arbetsplatsnamn',
      standard_statistics_header_workplaceAddress: 'Arbetsplatsadress',
      standard_statistics_header_container: 'Behållare',
      standard_statistics_header_quantity: 'Antal',
      standard_statistics_header_unit: 'Enhet',
      standard_statistics_header_netPrice: 'Nettopris',
      standard_statistics_header_price: 'Belopp',
      standard_statistics_header_wasteType: 'R&D kod',
      standard_statistics_header_wasteSubCode: 'R&D underkod',
      standard_statistics_header_wasteCode: 'EWC kod',
      standard_statistics_header_externalCode: 'BEAst nr',
      standard_statistics_header_billNumber: 'Vågsedelnummer',
      standard_statistics_header_loadCarrier: 'Fordon',
      standard_statistics_header_littera: 'Littera',
      standard_statistics_header_weight: 'Vikt (kg)',
      standard_statistics_header_volume: 'Volym (kbm)',
      standard_statistics_header_co2Saving: 'CO2 påverkan (kg)*',
    },
  },
};
